// ConnectionPanel.js
import React, { useEffect, useRef } from 'react';
import styles from './ConnectionPanel.module.css';

const ConnectionPanel = ({
  socketConnected,
  inputThreadId,
  setInputThreadId,
  instructText,
  setInstructText,
  prependText,
  setPrependText,
  appendText,
  setAppendText,
  connectSocket,
  disconnectSocket
}) => {
  const instructionsRef = useRef(null);
  const prependRef = useRef(null);
  const appendRef = useRef(null);

  // Set initial values for contentEditable divs without re-rendering on each keystroke
  useEffect(() => {
    if (instructionsRef.current) {
      instructionsRef.current.innerText = instructText;
    }
    if (prependRef.current) {
      prependRef.current.innerText = prependText;
    }
    if (appendRef.current) {
      appendRef.current.innerText = appendText;
    }
  }, [instructText, prependText, appendText]);

  // Function to update state on blur event
  const handleBlur = (ref, setState) => {
    if (ref.current) {
      setState(ref.current.innerText);
    }
  };

  return (
    <div className={styles.connectionControls}>
      <div className={styles.textLabel}>Thread ID</div>
      <input
        type="text"
        placeholder="Thread ID"
        value={inputThreadId}
        onChange={(e) => setInputThreadId(e.target.value)}
        disabled={socketConnected}
        className={styles.input}
      />

      <div className={styles.textLabel}>Instructions</div>
      <div
        contentEditable
        className={styles.instructionsEditable}
        ref={instructionsRef}
        onBlur={() => handleBlur(instructionsRef, setInstructText)}
        suppressContentEditableWarning={true}
      />

      <div className={styles.textLabel}>Prepend to Message</div>
      <div
        contentEditable
        className={styles.instructionsEditable}
        ref={prependRef}
        onBlur={() => handleBlur(prependRef, setPrependText)}
        suppressContentEditableWarning={true}
      />

      <div className={styles.textLabel}>Append to Message</div>
      <div
        contentEditable
        className={styles.instructionsEditable}
        ref={appendRef}
        onBlur={() => handleBlur(appendRef, setAppendText)}
        suppressContentEditableWarning={true}
      />

      <button onClick={connectSocket} disabled={socketConnected} className={styles.connectButton}>
        Connect
      </button>
      <button onClick={disconnectSocket} disabled={!socketConnected} className={styles.disconnectButton}>
        Disconnect
      </button>
    </div>
  );
};

export default ConnectionPanel;
