import React, { useState, useRef, useEffect } from 'react';
import styles from './Chat.module.css';

function Chat({ messages, sendMessage }) {
  const [messageInput, setMessageInput] = useState('');  // Capture user input
  const chatContainerRef = useRef(null);
  let lastWhoSpeaks = '';  // Store last whoSpeaks statically for use in assistant messages
  let lastWhoAvatar = '';  // Store last whoAvatar statically for use in assistant messages

  // Automatically scroll to the bottom when new messages are added
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSend = () => {
    if (messageInput.trim()) {
      sendMessage(messageInput);  // Send the user input to the ChatProcessor
      setMessageInput('');  // Clear the input field after sending
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();  // Send the message on Enter key press
    }
  };

  return (
    <div className={styles.chatWrapper}>
      {/* Display messages */}
      <div className={styles.chatContainer} ref={chatContainerRef}>
        {messages.map((msg, idx) => (
          <div key={idx} className={styles.messageContainer}>
            <div className={`${styles.message} ${msg.role === 'assistant' ? styles.assistantMessage : styles.userMessage}`}>
              {/* For assistant messages, display avatar and whoSpeaks */}
              {msg.role === 'assistant' ? (
                <div className={styles.avatarHolder}>
                  <img className={styles.responseAvatar} src={lastWhoAvatar} alt="User Avatar" />
                  {lastWhoSpeaks}
                </div>  // Print the stored user whoSpeaks and avatar for assistant
              ) : (
                /* Only store the whoSpeaks and whoAvatar values for the next assistant message, don't display them */
                (lastWhoSpeaks = msg.whoSpeaks, lastWhoAvatar = msg.whoAvatar) && null  // Store the values without rendering
              )}
              <div dangerouslySetInnerHTML={{ __html: msg.content }} />
            </div>
          </div>
        ))}
      </div>

      {/* Input area for sending messages */}
      <div className={styles.inputContainer}>
        <textarea
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}  // Capture user input
          onKeyDown={handleKeyDown}  // Handle key press for SHIFT + Enter
          placeholder="Type a message..."
          className={styles.inputField}
          rows="1"  // Start with one row
        />
        <button onClick={handleSend} className={styles.sendButton}>
          Send
        </button>
      </div>
    </div>
  );
}

export default Chat;
