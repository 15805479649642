// Connection.js
import React, { useEffect, useRef } from 'react';
import { io } from 'socket.io-client';

const Connection = ({
  setSocketRef,
  setSocketConnected,
  setThreadId,
  handleStreamedMessage,
  completeStreaming,
  threadId,
  instructText,
  prependText,
  appendText,
}) => {
  const socketRef = useRef(null);

  const connectSocket = () => {
    const rootServer = process.env.REACT_APP_ROOT_SERVER;

    if (!socketRef.current) {  // Ensure socketRef.current is null before creating a new connection
      socketRef.current = io(rootServer, {
        path: '/playsocket',
        transports: ['websocket'],
        withCredentials: false,
      });

      socketRef.current.on('connect', () => {
        setSocketConnected(true);
        setSocketRef(socketRef.current);

        // Emit initialize with the latest instructText, prependText, and appendText
        socketRef.current.emit('initialize', {
          threadId: threadId || null,
          assistantId: process.env.REACT_APP_ASSISTANT_ID,
          instructions: instructText,   // Send updated instructText
          prepend: prependText,         // Send updated prependText
          append: appendText            // Send updated appendText
        });

        socketRef.current.on('threadId', (data) => setThreadId(data.threadId));
        socketRef.current.on('message', handleStreamedMessage);
        socketRef.current.on('streaming_end', completeStreaming);
      });

      socketRef.current.on('disconnect', () => {
        setSocketConnected(false);
      });
    }
  };

  const disconnectSocket = () => {
    if (socketRef.current) {  // Check if socketRef.current is defined before attempting to disconnect
      socketRef.current.disconnect();
      socketRef.current = null;  // Set socketRef.current to null after disconnecting
      setSocketConnected(false);
    }
  };

  // Ensure connectSocket is always updated with the latest instructText, prependText, and appendText
  useEffect(() => {
    window.connectSocket = connectSocket;
    window.disconnectSocket = disconnectSocket;
  }, [connectSocket, disconnectSocket, instructText, prependText, appendText]);

  return null;
};

export default Connection;
