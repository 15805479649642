import React, { useState, useEffect, useRef } from 'react';
import Chat from './Chat';  // Chat component that handles rendering

function ChatProcessor({ socketRef, prependText, appendText, whoTalk, whoAvatar, isStreaming, handleStreamedMessage, completeStreaming }) {
  const [messages, setMessages] = useState([]);  // Track messages for display
  const previousChunkRef = useRef('');  // Track the last chunk of received message
  const currentStreamedDivRef = useRef(null);  // Reference for the current streamed message div

  // Automatically scroll to the bottom when new messages are added
  const scrollToBottom = (chatContainerRef) => {
    if (chatContainerRef && chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (socketRef.current) {
      // Listen for incoming messages from the server
      socketRef.current.on('message', (data) => {
        if (data.role === 'assistant') {
          const newChunk = data.content;

          // Only append the new chunk if it's different from the last one
          if (newChunk !== previousChunkRef.current) {
            previousChunkRef.current = newChunk;

            setMessages((prevMessages) => {
              const lastMessage = prevMessages[prevMessages.length - 1];

              // If the last message is from the assistant, append to it
              if (lastMessage?.role === 'assistant') {
                return prevMessages.map((msg, idx) =>
                  idx === prevMessages.length - 1
                    ? { ...msg, content: msg.content + newChunk }
                    : msg
                );
              }

              // Otherwise, add a new assistant message
              return [...prevMessages, { role: 'assistant', content: newChunk }];
            });
          }
        } else {
          // Add the user message with whoSpeaks
          setMessages((prevMessages) => [
            ...prevMessages,
            { ...data, whoSpeaks: data.whoTalk, whoAvatar: data.whoAvatar }
          ]);
        }
        scrollToBottom(currentStreamedDivRef);  // Scroll to bottom after new message
      });

      socketRef.current.on('streaming_end', () => {
        currentStreamedDivRef.current = null;  // Reset the streaming div reference
        previousChunkRef.current = '';  // Clear the last chunk tracker
        completeStreaming();  // Mark streaming as completed
      });
    }
  }, [socketRef, completeStreaming]);

  // Send the message to the server
  const sendMessage = (messageInput) => {
    if (!messageInput.trim() || isStreaming || !socketRef.current) return;

    const finalMessage = `${prependText ? prependText + ' ' : ''} Ask ${whoTalk}: ${messageInput}${appendText ? ' ' + appendText : ''}`;

    // Send the final message to the server
    socketRef.current.emit('message', {
      content: finalMessage
    });

    // Display the user's original input in the chat UI with whoSpeaks
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: messageInput, whoSpeaks: whoTalk, whoAvatar: whoAvatar }
    ]);
  };

  // Render the Chat component, passing messages and sendMessage
  return <Chat messages={messages} sendMessage={sendMessage} />;
}

export default ChatProcessor;
