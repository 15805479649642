// App.js
import React, { useState, useRef, useEffect } from 'react';
import ChatProcessor from './ChatProcessor';
import ShowGroup from './ShowGroup';
import ShowGroupRive from './ShowGroupRive';
import ConnectionPanel from './ConnectionPanel';
import Connection from './Connection';
import styles from './App.module.css';

function App() {
  const [threadId, setThreadId] = useState('');
  const [socketConnected, setSocketConnected] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [prependText, setPrependText] = useState('');
  const [appendText, setAppendText] = useState('');
  const [instructText, setInstructText] = useState('');
  const [whoGroup, setWhoGroup] = useState(null);
  const [whoIndex, setWhoIndex] = useState(null);
  const [whoTalk, setWhoTalk] = useState(null);
  const [whoAvatar, setWhoAvatar] = useState(null);
  const [showType, setShowType] = useState(null);
  const socketRef = useRef(null);

  const [config, setConfig] = useState(null);

  // Fetch config.json only once on component load
  useEffect(() => {
    fetch(`/config.json`)
      .then((response) => response.json())
      .then((data) => {
        setConfig(data.main);
        setInstructText(data.main.connection.instruct);
        setPrependText(data.main.connection.prepend);
        setAppendText(data.main.connection.append);
        setShowType(data.main.settings.showtype);

        const { group } = data.main.personas;
        setWhoGroup(data.main.personas);

        if (whoIndex === null && group.length > 0) {
          setWhoIndex(Math.floor(Math.random() * group.length));
        }
      })
      .catch((error) => {
        console.error('Error fetching config.json:', error);
      });
  }, []);

  // Update whoTalk and whoAvatar whenever whoIndex changes
  useEffect(() => {
    if (whoGroup && whoIndex !== null && whoGroup.group[whoIndex]) {
      setWhoTalk(whoGroup.group[whoIndex].name);
      setWhoAvatar(whoGroup.group[whoIndex].avatar);
    }
  }, [whoIndex, whoGroup]);

  const connectSocket = () => window.connectSocket();
  const disconnectSocket = () => window.disconnectSocket();

  return (
    <div className={styles.appWrapper}>
      <header className={styles.header}>
        {config && <p>{config.settings.title}</p>}
      </header>

      <div className={styles.contentWrapper}>
        {/* Left Column - ShowGroup or ShowGroupRive based on showType */}
        <div className={styles.leftColumn}>
          {whoGroup && showType === "avatar" && (
            <ShowGroup
              personas={whoGroup}
              whoIndex={whoIndex}
              setWhoIndex={setWhoIndex}
            />
          )}
          {whoGroup && showType === "rive" && (
            <ShowGroupRive
              personas={whoGroup}
              whoIndex={whoIndex}
              setWhoIndex={setWhoIndex}
            />
          )}
        </div>

        {/* Center Column - Chat Processor */}
        <div className={styles.centerColumn}>
          {socketConnected && (
            <ChatProcessor
              socketRef={socketRef}
              prependText={prependText}
              appendText={appendText}
              whoTalk={whoTalk}
              whoAvatar={whoAvatar}
              isStreaming={isStreaming}
              handleStreamedMessage={(data) => setIsStreaming(true)}
              completeStreaming={() => setIsStreaming(false)}
            />
          )}
        </div>

        {/* Right Column - ConnectionPanel */}
        <div className={styles.rightColumn}>
          <ConnectionPanel
            socketConnected={socketConnected}
            inputThreadId={threadId}
            setInputThreadId={setThreadId}
            instructText={instructText}
            setInstructText={setInstructText}
            prependText={prependText}
            setPrependText={setPrependText}
            appendText={appendText}
            setAppendText={setAppendText}
            connectSocket={connectSocket}
            disconnectSocket={disconnectSocket}
          />
        </div>
      </div>

      {/* Renderless Connection component to manage socket connection */}
      <Connection
        setThreadId={setThreadId}
        threadId={threadId}
        setSocketRef={(socket) => { socketRef.current = socket; }}
        socketConnected={socketConnected}
        setSocketConnected={setSocketConnected}
        handleStreamedMessage={(data) => setIsStreaming(true)}
        completeStreaming={() => setIsStreaming(false)}
        instructText={instructText}
        prependText={prependText}
        appendText={appendText}
      />

      <footer className={styles.footer}>
        <p></p>
      </footer>
    </div>
  );
}

export default App;
