import React, { useCallback } from 'react';
import styles from './ShowGroupRive.module.css';
import Rive from '@rive-app/react-canvas';

// Debounce utility function to limit rapid calls
function debounce(func, delay) {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

function ShowGroupRive({ personas, whoIndex, setWhoIndex }) {
  // Debounced version of the click handler
  const debouncedSetWhoIndex = useCallback(debounce((index) => {
    console.log("Debounced Avatar clicked, setting whoIndex:", index);
    setWhoIndex(index);
  }, 200), [setWhoIndex]);

  return (
    <div className={styles.showGroupContainer}>
      {personas.group.map((persona, index) => (
        <div
          key={index}
          className={styles.avatarContainer}
          style={{
            filter: index === whoIndex ? 'none' : 'grayscale(80%)',  // Apply desaturation
          }}
          onClick={(e) => {
            e.stopPropagation();  // Prevent event bubbling
            debouncedSetWhoIndex(index);  // Debounced click handler
          }}
        >

        <Rive className={styles.full} src={persona.rive} stateMachines="StateMachine1"/>

          <p>{persona.name} – {persona.career}</p>
        </div>
      ))}
    </div>
  );
}

export default ShowGroupRive;
